import React, { Component } from "react";
import "./ContactUsScript.css";
import { Grid, Segment, Header, Button, Icon } from "semantic-ui-react";
import MapForm from "./MapAndForm/MapForm";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class ContactUs extends Component {
  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <Helmet>
          <title>Contact Us - Metalac Industries</title>
          <meta
            name="contact"
            content="Contact Us at Metalac Industries to Learn more about our one of The Leading Foundary in the Region! Learn about our Product line and Order Details"
          />
        </Helmet>
        <div
          className="contact-us-background"
          style={{
            display: "table",
            position: "relative",
            width: "100%",
            padding: "10% 5%",
            textAlign: "center",
          }}
        >
          {/* <div className="dark-tint" /> */}
          <Header
            id="services-page-heading"
            as="h1"
            inverted
            style={{
              display: "table-cell",
              position: "relative",
              zIndex: "4",
              textAlign: "left",
              verticalAlign: "middle",
            }}
          >
            <Header.Content>
              <p className="company-name">Metalac Industries</p>
            </Header.Content>
            <Header.Subheader>
              We're here to answer all your Queries!
            </Header.Subheader>
            <Button
              as={Link}
              to="/about"
              animated
              inverted
              size="large"
              style={{ marginTop: "2%" }}
            >
              <Button.Content visible>Know more about Us!</Button.Content>
              <Button.Content hidden>
                <Icon name="shopping basket" />
              </Button.Content>
            </Button>
          </Header>
        </div>

        <Segment
          secondary
          basic
          textAlign="left"
          style={{ margin: "0px 0px", padding: "5% 2%" }}
        >
          <Grid divided stackable>
            <Grid.Row divided>
              <Grid.Column width="10">
                <MapForm />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width="6">
                <Header color="red">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    {" "}
                    Contact Information{" "}
                  </p>
                </Header>

                <p className="small-text" style={{ fontWeight: "lighter" }}>
                  For any support and queries Email us at:-{" "}
                  <a href="mailto:piyush@metalacindustries.com">
                    piyush@metalacindustries.com
                  </a>
                </p>
                <p className="small-text" style={{ fontWeight: "lighter" }}>
                  Call: <a href="tel:+919216600785">(+91) 92166-00785</a>
                </p>
                <Header color="red">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    {" "}
                    Industry Address{" "}
                  </p>
                </Header>
                <p className="small-text" style={{ fontWeight: "bold" }}>
                  Kotkapura
                </p>
                <p>
                  Devi Wala Road, Near BRK Rice Land, Kotkapura, Punjab - 151204
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <div style={{ height: "50vh", width: "100%" }}>
          <iframe
            title="Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1083.1735776080661!2d74.86780833072294!3d30.60683351533095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDM2JzI1LjUiTiA3NMKwNTInMDQuOCJF!5e1!3m2!1sen!2sin!4v1578464700775!5m2!1sen!2sin"
            frameBorder="0"
            style={{ border: "none", width: "100%", height: "100%" }}
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    );
  }
}

export default ContactUs;
