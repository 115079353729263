import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Image,
  Grid,
  Container,
  Header,
  Divider,
  Button,
  Icon,
  Segment
} from "semantic-ui-react";

export default class LPProcess extends Component {
  state = {
    selectedItem: 0
  };

  handleSelectedStateChange = item => () => {
    this.setState({
      selectedItem: item
    });
  };

  render() {
    return (
      <Segment basic style={{ padding: "2% 2%" }}>
        <Divider horizontal>
          <Header>
            <Header.Content>
              <p className="large-text" style={{ fontWeight: "bold" }}>
                Our Facilities
              </p>
            </Header.Content>

            <Header.Subheader>
              <p className="small-text">Here is a brief Overview</p>
            </Header.Subheader>
          </Header>
        </Divider>
        <div style={{ marginTop: "4%" }}>
          <Carousel
            infiniteLoop={false}
            showStatus={false}
            stopOnHover={true}
            showThumbs={false}
            showArrows={false}
            autoPlay={false}
            showIndicators={false}
            swipeable={false}
            selectedItem={this.state.selectedItem}
          >
            <div>
              <Grid
                verticalAlign="middle"
                relaxed
                stackable
                columns="equal"
                style={{ backgroundColor: "white" }}
              >
                <Grid.Column>
                  <Grid verticalAlign="middle" columns="2">
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/core/scs.jpg"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/core/cbcs.jpg"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    basic
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Header textAlign="left" as="h1">
                      <Header.Content>Core Manufacturing</Header.Content>
                      <Divider />
                      <Header.Subheader>
                        <Container>
                          <b>About Process</b>

                          <p style={{ marginTop: "1%" }}>
                            Core making is the process which forms the interior
                            part of the casting. The mould provides a space for
                            the molten metal to go, while the core keeps the
                            metal from filling the entire space. Cores are
                            placed into a mould cavity to form the interior
                            surfaces of castings. Thus the void space is filled
                            with molten metal and eventually becomes the
                            casting. <a href="/process/core">Learn More</a>
                          </p>
                          <Button
                            labelPosition="right"
                            icon
                            onClick={this.handleSelectedStateChange(1)}
                          >
                            <Icon name="forward" />
                            Next
                          </Button>
                        </Container>
                      </Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
            <div>
              <Grid verticalAlign="middle" stackable columns="equal">
                <Grid.Column>
                  <Grid columns="2">
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/moulding/hpmm.jpg"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/moulding/dc.jpg"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    basic
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Header textAlign="left" as="h1">
                      <Header.Content>Moulding</Header.Content>
                      <Divider />
                      <Header.Subheader>
                        <Container>
                          <b>About Process</b>

                          <p style={{ marginTop: "1%" }}>
                            Mold making is a vital process in which a material
                            is used for making the mold of the original model.
                            Different materials can be used for mold making
                            process depending on each one's needs and
                            preference. <a href="/process/core">Learn More</a>
                            <br />
                          </p>
                          <Button
                            color="orange"
                            labelPosition="left"
                            icon
                            onClick={this.handleSelectedStateChange(0)}
                          >
                            <Icon name="backward" />
                            Previous
                          </Button>
                          <Button
                            labelPosition="right"
                            icon
                            onClick={this.handleSelectedStateChange(2)}
                          >
                            <Icon name="forward" />
                            Next
                          </Button>
                        </Container>
                      </Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
            <div>
              <Grid verticalAlign="middle" stackable columns="equal">
                <Grid.Column>
                  <Grid columns="2">
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/melting/indf.jpg"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/melting/panel.jpg"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    basic
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Header textAlign="left" as="h1">
                      <Header.Content>Melting</Header.Content>
                      <Divider />
                      <Header.Subheader>
                        <Container>
                          <b>About Process</b>

                          <p style={{ marginTop: "1%" }}>
                            The preparation of molten metal for casting is
                            referred to as melting. It is usually done in a
                            specifically designated area of the foundry, and the
                            molten metal is transferred to the pouring area
                            where the molds are filled.
                            <a href="/process/core">Learn More</a>
                            <br />
                          </p>
                          <Button
                            color="orange"
                            labelPosition="left"
                            icon
                            onClick={this.handleSelectedStateChange(1)}
                          >
                            <Icon name="backward" />
                            Previous
                          </Button>
                          <Button
                            labelPosition="right"
                            icon
                            onClick={this.handleSelectedStateChange(3)}
                          >
                            <Icon name="forward" />
                            Next
                          </Button>
                        </Container>
                      </Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
            <div>
              <Grid verticalAlign="middle" stackable columns="equal">
                <Grid.Column>
                  <Grid columns="2">
                    <Grid.Column>
                      <Image
                        size="large"
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/testing/metallurgy_lab.jpg"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        size="large"
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/testing/physical_lab.jpg"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    basic
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Header textAlign="left" as="h1">
                      <Header.Content>Testing</Header.Content>
                      <Divider />
                      <Header.Subheader>
                        <Container>
                          <b>About Process</b>

                          <p style={{ marginTop: "1%" }}>
                            Testing and inspection systems refer to a variety of
                            machines and equipment that are used for testing,
                            inspection and analysis purposes in foundries. These
                            equipment are used to ensure that the material meets
                            the prerequisites of the specification; accordingly,
                            testing is mandatory.{" "}
                            <a href="/process/core">Learn More</a>
                          </p>
                          <Button
                            color="orange"
                            labelPosition="left"
                            icon
                            onClick={this.handleSelectedStateChange(2)}
                          >
                            <Icon name="backward" />
                            Previous
                          </Button>
                          <Button
                            labelPosition="right"
                            icon
                            onClick={this.handleSelectedStateChange(4)}
                          >
                            <Icon name="forward" />
                            Next
                          </Button>
                        </Container>
                      </Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
            <div>
              <Grid verticalAlign="middle" stackable columns="equal">
                <Grid.Column>
                  <Grid columns="2">
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/finishing/sb.jpg"
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        fluid
                        style={{ backgroundColor: "white" }}
                        src="/assets/process/finishing/gaf.jpg"
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    basic
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Header textAlign="left" as="h1">
                      <Header.Content>Finishing</Header.Content>
                      <Divider />
                      <Header.Subheader>
                        <Container>
                          <b>About Process</b>

                          <p style={{ marginTop: "1%" }}>
                            Casting finishing is the process of taking cooled,
                            molded cast assemblies and preparing them for use.
                            Depending on the process used, (nobake, green sand,
                            investment, etc.) the castings will require various
                            levels of finishing. The type of metal cast also
                            plays a part in the finishing process.
                            <a href="/process/core">Learn More</a>
                          </p>
                          <Button
                            color="orange"
                            labelPosition="left"
                            icon
                            onClick={this.handleSelectedStateChange(3)}
                          >
                            <Icon name="backward" />
                            Previous
                          </Button>
                        </Container>
                      </Header.Subheader>
                    </Header>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
          </Carousel>
        </div>
      </Segment>
    );
  }
}
