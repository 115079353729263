import React from "react";
import { Segment, Grid, Image, Divider, Header } from "semantic-ui-react";

export const Certifications = ({ props }) => {
  return (
    <Segment id="downloads" basic secondary style={{ marginTop: "0px" }}>
      <Divider horizontal>
        <Header>
          <Header.Content>
            <p className="large-text">
              <b style={{ fontWeight: "bold" }}>Certifications and Downloads</b>
            </p>
          </Header.Content>

          <Header.Subheader>
            <p className="small-text">We believe in Quality</p>
          </Header.Subheader>
        </Header>
      </Divider>
      <Grid padded textAlign="center" columns="equal" centered>
        <Grid.Column only="computer tablet"></Grid.Column>
        <Grid.Column textAlign="center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/downloads%2FMetalac%20Industries%20-%209001%20Certification.pdf?alt=media&token=6e961d25-68ac-4c63-8e87-e40791f4245e"
          >
            <Image centered size="small" src="assets/cert/mi_9001.jpg" />
            <Header>
              ISO <br /> 9001: 2015
            </Header>
          </a>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/downloads%2FMetalac%20Industries%20-%2014001%20Certification.pdf?alt=media&token=7b4b1669-4f76-49fd-b461-3197c7f957f0"
          >
            <Image centered size="small" src="assets/cert/mi_14001.jpg" />
            <Header>
              ISO <br /> 14001: 2015
            </Header>
          </a>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://firebasestorage.googleapis.com/v0/b/metalac-industries-ws.appspot.com/o/downloads%2FMetalac%20Industries%20-%20Catalog.pdf?alt=media&token=5ac4de64-9736-43e5-bbf6-21d3c3355729"
          >
            <Image centered size="small" src="assets/cert/catalog_lp.jpg" />
            <Header>
              Digital <br />
              Catalog
            </Header>
          </a>
        </Grid.Column>
        <Grid.Column only="computer tablet"></Grid.Column>
      </Grid>
    </Segment>
  );
};
