import React, { Component } from "react";
import { Form, Segment, Header, Button } from "semantic-ui-react";
import axios from "axios";

let warningStatus = false;
let resultMessage = "";
let sendingMessage = false;
let bookingDone = false;

const emptyState = {
  name: "",
  contact: "",
  email: "",
  query: "",
};

export default class BookingForm extends Component {
  state = {
    name: "",
    contact: "",
    email: "",
    query: "",
  };

  sendMessage = (data) => {
    try {
      sendingMessage = true;
      resultMessage = "";
      this.setState({});
      axios
        .post("", data)
        .then((res) => {
          resultMessage = res.data.result;
          bookingDone = res.data.done;
          sendingMessage = false;
          warningStatus = false;
          this.setState(emptyState);
        })
        .catch((e) => {
          bookingDone = false;
          resultMessage = "There was an error Sending Message!";
          sendingMessage = false;
          this.setState({});
        });
    } catch (e) {
      resultMessage = "There was an error Sending Message!";
      sendingMessage = false;
      this.setState({});
    }
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        [fieldName]: value,
      };
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  handleTimeChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleCabTypeChange = (e, { value }) => {
    this.setState({
      cabType: value,
    });
  };

  onPickupSuburbChange = (value) => {
    this.setState({
      pickupSuburb: value,
    });
  };

  onDropOffSuburbChange = (value) => {
    this.setState({
      dropOffSuburb: value,
    });
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (warningStatus && fieldValue.trim() === "") {
      return true;
    }
    return false;
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (key !== "notifyPass") {
          let value = this.state[key];
          if (value.trim() === "") {
            valid = false;
          }
        }
      });
    }
    return valid;
  };

  render() {
    return (
      <div id="booking-form">
        <Segment
          basic
          secondary
          style={{ margin: "2% 2%" }}
          loading={sendingMessage}
        >
          <Header color="green">{resultMessage}</Header>
          {!bookingDone && (
            <Form warning={warningStatus}>
              {warningStatus && (
                <Header color="red"> Please fill data correctly!</Header>
              )}

              <Header as="h1">
                <Header.Content>We Are here for your Queries</Header.Content>

                <Header.Subheader>
                  Please fill out a quick form and we will be in touch with
                  lightening speed.
                </Header.Subheader>
              </Header>

              <Form.Field error={this.showWarning("name")}>
                <label>Name</label>
                <input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  name="name"
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <Form.Field error={this.showWarning("contact")}>
                <label>Phone</label>
                <input
                  placeholder="Phone"
                  type="number"
                  name="contact"
                  value={this.state.contact}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <Form.Field error={this.showWarning("email")}>
                <label>Email</label>
                <input
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <Form.Field error={this.showWarning("query")}>
                <label>Message</label>
                <textarea
                  placeholder="Enter a Message for Us!"
                  name="query"
                  value={this.state.query}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <Button
                  color="black"
                  onClick={() => {
                    this.handleFormSubmit();
                  }}
                >
                  Send Message
                </Button>
              </Form.Field>
            </Form>
          )}
        </Segment>
      </div>
    );
  }
}
