import React from "react";
import { Grid, Image, Header, Divider } from "semantic-ui-react";

export const OurSpeciality = ({ showHeading }) => {
  return (
    <div style={{ position: "relative", padding: "2% 2%" }}>
      {showHeading === true && (
        <Divider horizontal>
          <Header>
            <Header.Content>
              <p className="large-text">
                Why choose{" "}
                <b style={{ fontWeight: "bold" }}>Metalac Industries</b>
              </p>
            </Header.Content>

            <Header.Subheader>
              <p className="small-text">We strive to be the best!</p>
            </Header.Subheader>
          </Header>
        </Divider>
      )}

      <Grid padded>
        <Grid.Row columns="equal">
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Image
              centered
              size="medium"
              circular
              src="/assets/images/cexp.png"
            />{" "}
            <p className="large-text" style={{ color: "black" }}>
              Casting <br /> Experts
            </p>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Image centered size="medium" src="/assets/images/cusrel.png" />
            <p className="large-text" style={{ color: "black" }}>
              Customer <br /> Reliability
            </p>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Image centered size="medium" src="/assets/images/env.png" />
            <p className="large-text" style={{ color: "black" }}>
              Eco <br /> Friendly
            </p>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Image centered size="medium" src="/assets/images/mach.png" />
            <p className="large-text" style={{ color: "black" }}>
              Machining <br /> Experts
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
