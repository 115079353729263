import React from "react";
import { Grid, Image, Header, Card } from "semantic-ui-react";
import { Helmet } from "react-helmet";

export const PollutionControl = ({ props }) => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Metalac Industries</title>
        <meta
          name="contact"
          content="Contact Us at Metalac Industries to Learn more about our one of The Leading Foundary in the Region! Learn about our Product line and Order Details"
        />
      </Helmet>
      <div
        className="pollution-control-background"
        style={{
          display: "table",
          position: "relative",
          width: "100%",
          padding: "10% 5%",
          textAlign: "center",
        }}
      >
        <div className="dark-tint" />
        <Header
          id="services-page-heading"
          as="h1"
          inverted
          style={{
            display: "table-cell",
            position: "relative",
            zIndex: "4",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          <Header.Content>
            <p className="company-name">Pollution Control</p>
          </Header.Content>
          <Header.Subheader>
            We search for Solution, not for Pollution
          </Header.Subheader>
        </Header>
      </div>
      <Grid padded stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid>
              <Image
                src="/assets/process/pollution_control/apc_galv.jpg"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Air Pollution Control Device</Card.Header>
                <Card.Meta>
                  <span className="date">for Galvanization Process</span>
                </Card.Meta>
                <Card.Description>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Capacity: </span> 6 NOS
                    Furnace {" | "}
                    <span style={{ fontWeight: "bold" }}>
                      Flue Gas Volume:{" "}
                    </span>{" "}
                    27,000 m³ / Hr{" "}
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Image
                src="/assets/process/pollution_control/apc_ind.jpg"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Air Pollution Control Device</Card.Header>
                <Card.Meta>
                  <span className="date">for Induction Furnace</span>
                </Card.Meta>
                <Card.Description>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Capacity: </span> 0.5
                    Ton x 3 NOS (1250 KWH) <br />{" "}
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Card fluid>
              <Image
                src="/assets/process/pollution_control/etp.jpg"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Effluent Treatment Plant</Card.Header>
                <Card.Meta>
                  <span className="date">for Galvanization Process</span>
                </Card.Meta>
                <Card.Description>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Capacity: </span> 5 KL
                    / Batch
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Image
                src="/assets/process/pollution_control/stp.jpg"
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header>Sewage Treatment Plant</Card.Header>
                <Card.Meta>
                  <span className="date">for Industry</span>
                </Card.Meta>
                <Card.Description>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Capacity: </span> 25
                    KLD <br />{" "}
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
