import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";

export default class TopBar extends Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Grid inverted centered>
          <Grid.Row
            centered
            style={{ backgroundColor: "black" }}
            columns="equal"
          >
            <Grid.Column textAlign="center">
              <a href="tel:+919216600785">
                <Button
                  className="shrink_on_hover"
                  inverted
                  basic
                  circular
                  style={{ margin: "1% 2% 1% 2%" }}
                  size="small"
                  color="yellow"
                  icon="phone"
                />
                <span style={{ color: "white" }}>Call</span>
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <a href="mailto:metalacindustries@gmail.com">
                <Button
                  className="shrink_on_hover"
                  inverted
                  basic
                  circular
                  style={{ margin: "1% 2% 1% 2%" }}
                  size="small"
                  color="google plus"
                  icon="mail"
                />
                <span style={{ color: "white" }}>E-Mail</span>
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
