import React, { Component } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import "./CategoryBar.css";

class CategoryBar extends Component {
  render() {
    return (
      <div className="category_holder" id="category_holder">
        <Menu
          widths={5}
          icon="labeled"
          pointing
          stackable
          style={{
            borderTop: "1px solid black",
            marginTop: "0px",
            marginBottom: "0px",
            borderRadius: "0"
          }}
        >
          <Menu.Item
            name="core"
            active={this.props.active === "core"}
            as={Link}
            to="/process/core"
          >
            <Icon name="cube" />
            <p className="small-text">Core Manuf.</p>
          </Menu.Item>
          <Menu.Item
            name="moulding"
            active={this.props.active === "moulding"}
            as={Link}
            to="/process/moulding"
          >
            <Icon name="inbox" />
            <p className="small-text">Moulding</p>
          </Menu.Item>

          <Menu.Item
            name="melting"
            active={this.props.active === "melting"}
            as={Link}
            to="/process/melting"
          >
            <Icon name="fire" />
            <p className="small-text">Melting</p>
          </Menu.Item>
          <Menu.Item
            name="testing"
            active={this.props.active === "testing"}
            as={Link}
            to="/process/testing"
          >
            <Icon name="lab" />
            <p className="small-text">Testing</p>
          </Menu.Item>
          <Menu.Item
            name="finishing"
            active={this.props.active === "finishing"}
            as={Link}
            to="/process/finishing"
          >
            <Icon name="gem" />
            <p className="small-text">Finishing</p>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default withRouter(CategoryBar);
