export const processInformation = {
  core: {
    name: "Core Manufacturing",
    description:
      "Core making is the process which forms the interior part of the casting. The mould provides a space for the molten metal to go, while the core keeps the metal from filling the entire space. Cores are placed into a mould cavity to form the interior surfaces of castings. Thus the void space is filled with molten metal and eventually becomes the casting.",
    imageDetails: [
      {
        image: "/assets/process/core/cbcs.jpg",
        imageName: "Cold Box Core Shooter",
        description:
          "<p><b>Capacity: </b>12 Kg <br/> <b>Plate Size:</b> 600mm x 500mm x 200mm</p>"
      },
      {
        image: "/assets/process/core/scs.jpg",
        imageName: "Shell Core Shooter",
        description:
          "<p><b>Size: </b>300mm x 600mm <br/> <b>Info:</b> With one side Mechanical and other side Pneumatic (Fully PLC Controlled)</p>"
      }
    ]
  },
  moulding: {
    name: "Moulding",
    description:
      "Mold making is a vital process in which a material is used for making the mold of the original model. Different materials can be used for mold making process depending on each one's needs and preference. The mold flask is used as container for holding the mold. The mold flask is designed and separated into two halves to help in the process of mold making. The sand molds made are actually temporary molding. This results in the process of making new molds to carry out each individual casting of mold.",
    imageDetails: [
      {
        image: "/assets/process/moulding/hpmm.jpg",
        imageName: "High Pressure Moulding Machine",
        description: "<p><b>Capacity: </b> 60 Moulds Per Hour</p>"
      },
      {
        image: "/assets/process/moulding/sm.jpg",
        imageName: "Sand Muller",
        description: "<p><b>Capacity: </b>500 Kg</p>"
      },
      {
        image: "/assets/process/moulding/dc.jpg",
        imageName: "Dust Collector",
        description:
          "<p><b>Cope Box Size: </b>610mm x 610mm x 610mm <br/> <b>Drag Box Size: </b>610mm x 610mm x 610mm</p>"
      }
    ]
  },
  melting: {
    name: "Melting",
    description:
      "The preparation of molten metal for casting is referred to as melting. It is usually done in a specifically designated area of the foundry, and the molten metal is transferred to the pouring area where the molds are filled.  The melting process begins with the metal specification for the casting, determining the type of scrap metal to be used to 'charge' the furnace. Once charged, the furnace uses electrodes, each supplying roughly 6,500 amps of electricity, to melt the scrap metal. Samples are taken at various points in the melt process, to ascertain the chemical composition of the molten metal. Using a spectrometer as a guide, alloys are added to the furnace to bring the molten metal to the proper specification.",
    imageDetails: [
      {
        image: "/assets/process/melting/indf.jpg",
        imageName: "Induction Furnace (Tritrac)",
        description:
          "<p><b>Capacity: </b>350 KW Furnace with 500Kg crusible Capacity<br/> <b>Info: </b>1250 KW Tritrac Panel</p>"
      },
      {
        image: "/assets/process/melting/panel.jpg",
        imageName: "Panel for Induction Furnace"
      },
      {
        image: "/assets/process/melting/dmwp.jpg",
        imageName: "DM Water Pump"
      },
      {
        image: "/assets/process/melting/wp.jpg",
        imageName: "Water Pump"
      }
    ]
  },
  testing: {
    name: "Testing",
    description:
      "Testing and inspection systems refer to a variety of machines and equipment that are used for testing, inspection and analysis purposes in foundries. These equipment are used to ensure that the material meets the prerequisites of the specification; accordingly, testing is mandatory. More frequent testing, inspection or other tests may be enforced, by use of supplementary requirements of product specifications or specification of general requirements.",
    imageDetails: [
      {
        image: "/assets/process/testing/metallurgy_lab.jpg",
        imageName: "Metallurgy Lab"
      },
      {
        image: "/assets/process/testing/sand_lab.jpg",
        imageName: "Sand Lab"
      },
      {
        image: "/assets/process/testing/physical_lab.jpg",
        imageName: "Physical Lab"
      }
    ]
  },
  finishing: {
    name: "Finishing",
    description:
      "Casting finishing is the process of taking cooled, molded cast assemblies and preparing them for use. Depending on the process used, (nobake, green sand, investment, etc.) the castings will require various levels of finishing. The type of metal cast also plays a part in the finishing process.Once the castings are separated from the trees or risers, all flash and parting line metal must be removed. Each of these is a result of casting through the use of compacted molds. Depending on the casting finish quality, finishing may involve several steps of grinding from coarse to fine. After finishing, castings are inspected for surface quality. Inspection can be performed manually by visual checking, manually by template comparison or by an automated inspection station.",
    imageDetails: [
      {
        image: "/assets/process/finishing/sb.jpg",
        imageName: "Shot Blasting Machine"
      },
      {
        image: "/assets/process/finishing/gaf.jpg",
        imageName: "Grinding and Finishing Machines"
      },
      {
        image: "/assets/process/finishing/cnc.jpg",
        imageName: "CNC Machine"
      }
    ]
  }
};
