import React, { Component } from "react";
import { Header, Container, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { OurSpeciality } from "./OurSpeciality";
import LPProcess from "../Gallary/LPProcess";
import { Certifications } from "./Certifications";
import { Helmet } from "react-helmet";

export default class LandingPage extends Component {
  handleViewMoreClick = () => {
    var shoes = document.getElementById("custom-stock");
    if (shoes != null) {
      shoes.scrollIntoView(true);
    } else {
      alert("null");
    }
  };

  moveToElement = (elementID) => () => {
    var downloads = document.getElementById(elementID);
    if (downloads != null) {
      downloads.scrollIntoView(true);
    }
  };

  render() {
    return (
      <div id="lp-content" style={{ display: "block", position: "relative" }}>
        <Helmet>
          <title>Metalac Industries - Home</title>
          <meta
            name="metalac_industries"
            content="Metalac Industries is one of the leading Foundary Industries in India with quality product range."
          />
        </Helmet>
        <div
          className="lp-background"
          style={{
            display: "table",
            position: "relative",
            width: "100%",
            padding: "10% 5%",
            textAlign: "center",
          }}
        >
          <div className="gradient-tint" />
          <Header
            id="services-page-heading"
            as="h1"
            inverted
            style={{
              display: "table-cell",
              position: "relative",
              zIndex: "4",
              textAlign: "left",
              verticalAlign: "middle",
            }}
          >
            <Header.Content>
              <p className="company-name">SG IRON FOUNDRY</p>
            </Header.Content>
            <Header.Subheader>
              With a vision of providing high quality products.
            </Header.Subheader>
            <br />
            <Button color="orange" onClick={this.moveToElement("downloads")}>
              Our Certifications
            </Button>
          </Header>
        </div>

        <LPProcess />
        <div
          className="about-background"
          style={{
            display: "table",
            position: "relative",
            width: "100%",
            padding: "5% 5%",
            textAlign: "center",
          }}
        >
          <div className="dark-tint" />
          <Container
            style={{
              color: "white",
              position: "relative",
              zIndex: "4",
            }}
          >
            <p className="small-text" style={{ color: "white" }}>
              Metalac Industries, is a constantly growing SG Iron Foundry with
              assets and vision of providing high quality products to our
              associated customers. Our factory is located in Kotkapura (Punjab,
              India). As an organization in the manufacturing sector, we strive
              for the best and promise to provide high quality castings to our
              customers. <br />
            </p>
            <Button as={Link} to="/about" animated>
              {" "}
              <Button.Content visible>Know More about Us</Button.Content>
              <Button.Content hidden>
                {" "}
                <Icon name="arrow right" />
              </Button.Content>
            </Button>
          </Container>
        </div>
        <Certifications />
        <OurSpeciality showHeading={true} />
      </div>
    );
  }
}
