import React, { Component } from "react";
import { Grid, List, Header, Icon, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <Segment
        inverted
        className="footer-gradient"
        basic
        style={{ margin: "0px 0px", padding: "0px 0px" }}
      >
        <div style={{ padding: "1% 1%" }}>
          <Grid inverted padded stackable divided columns="equal">
            <Grid.Column mobile="16" tablet="16" computer="4">
              <p className="big-heading">Contact Us</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <Icon name="industry" />
                  <List.Content as={Link} to="/contact">
                    Devi Wala Road, Near BRK Rice Land, Kotkapura, Punjab -
                    151204
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="mail" />
                  <List.Content as={Link} to="/contact">
                    piyush@metalacindustries.com
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="call" />
                  <List.Content as={Link} to="/contact">
                    92166-00785
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <p className="big-heading">Product Range</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <List.Content>
                    <List.Header>AutoParts</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Insulator Metal Fittings</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Tractor Parts</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Railway Metal Fittings</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Electrical and Instrumentation</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <p className="big-heading">Quick Links</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <List.Content as={Link} to="/process/core">
                    <List.Header>Our Facilities</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/pollutioncontrol">
                    <List.Header>Pollution Control</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Careers</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/">
                    <List.Header>Certifications</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="4">
              <p className="big-heading">Navigation</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <List.Content as={Link} to="/">
                    <List.Header>Home</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/process/core">
                    <List.Header>Our Process</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/contact">
                    <List.Header>Contact Us</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/about">
                    <List.Header>Know About Us?</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </div>

        <Segment
          basic
          padded
          style={{
            backgroundColor: "white",
            margin: "0px 0px",
          }}
        >
          <Header as="h3" textAlign="center" style={{ lineHeight: "2em" }}>
            Copyright© Metalac Industries [2021]. All Rights Reserved <br />{" "}
            <p style={{ fontWeight: "lighter", fontSize: "0.7em" }}>
              Designed and Developed by{" "}
              <a
                style={{ color: "black", fontWeight: "bold" }}
                href="tel:+917626968921"
              >
                RetroPortal Studio.
              </a>{" "}
            </p>
          </Header>
        </Segment>
      </Segment>
    );
  }
}
