import React, { Component } from "react";
import { Segment, Header, Image, Container } from "semantic-ui-react";

import { Helmet } from "react-helmet";
import { OurSpeciality } from "../LandingPage/OurSpeciality";
import { Certifications } from "../LandingPage/Certifications";

export default class AboutUsPage extends Component {
  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <Helmet>
          <title>About Us - Metalac Industries</title>
          <meta
            name="about"
            content="Learn More About Metalac Industries,  a Leading Foundary constantly growing towards producing Top Grade Castings!"
          />
        </Helmet>
        <Segment
          secondary
          basic
          textAlign="center"
          style={{ margin: "0px 0px", padding: "5% 2%" }}
        >
          <Image centered src="assets/mi_outer.png" />

          <Header as="h1">
            <Header.Content>Metalac Industries</Header.Content>
            <Header.Subheader>Kotkapura</Header.Subheader>
          </Header>
          <Container textAlign="justified">
            <p className="large-text">
              Metalac Industries, is a constantly growing SG Iron Foundry with
              assets and vision of providing high quality products to our
              associated customers. Our factory is located in Kotkapura (Punjab,
              India). As an organization in the manufacturing sector, we strive
              for the best and promise to provide high quality castings to our
              customers . We deal in SG Iron Castings and Cast Iron Castings
              which include: <br />
              <b className="small-text" style={{ fontWeight: "bold" }}>
                - AutoParts <br /> - Insulator Metal Fittings <br /> - Tractor
                Parts <br /> - Railway Metal Fittings and Many More..{" "}
              </b>
              <br />
              <br />
            </p>
          </Container>
        </Segment>
        <Certifications />
        <OurSpeciality />
      </div>
    );
  }
}
