import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Sidebar, Segment, Menu, Responsive, Sticky } from "semantic-ui-react";
import { Link } from "react-router-dom";
import MobileNavbar from "./Components/Navbar/MobileNavbar";
import Navbar from "./Components/Navbar/Navbar";
// import Trainings from "./Components/Trainings/Trainings";
import ContactUs from "./Components/ContactUs/ContactUs";
import LandingPage from "./Components/LandingPage/LandingPage";
import TopBar from "./Components/Navbar/TopBar";
import Footer from "./Components/Footer/Footer";
import { isIE, isEdge } from "react-device-detect";
import AboutUsPage from "./Components/AboutUs/AboutUsPage";
import MainProcess from "./Components/Gallary/MainProcess";
import { PollutionControl } from "./Components/Gallary/PollutionControl";

class App extends Component {
  state = {
    sidebarOpen: false,
    logoSize: "medium",
    showLogo: true
  };

  setDivContext = context => {
    this.setState({
      divContext: context
    });
  };

  handleSidebarToggle = open => () => {
    this.setState({
      sidebarOpen: open
    });
  };

  handleNavbarStick = () => {
    this.setState({
      logoSize: "tiny",
      showLogo: false
    });
  };

  handleNavbarUnstick = () => {
    this.setState({
      logoSize: "large",
      showLogo: true
    });
  };

  handleLogoSize = size => () => {
    this.setState({
      logoSize: size
    });
  };

  handlePrivacyPolicyClick = () => {
    window.location = "https://sites.google.com/view/thenawties/privacy-policy";
  };

  checkIfSafari() {
    return isIE || isEdge;
  }

  showSupportAlert() {
    alert(
      "Please use Chrome, Mozilla or Any other High End Browser for Better Experience!"
    );
    window.open("https://www.google.com/chrome/");
  }

  render() {
    const { sidebarOpen } = this.state;
    return (
      <div ref={this.setDivContext}>
        {!this.checkIfSafari() && (
          <div>
            <Responsive maxWidth="991">
              <MobileNavbar
                handleSidebarToggle={this.handleSidebarToggle}
                value={this.state.sidebarOpen}
              />
            </Responsive>
            <Responsive minWidth="992">
              <TopBar />
              <Sticky
                onStick={this.handleNavbarStick}
                onUnstick={this.handleNavbarUnstick}
                context={this.state.divContext}
              >
                <Navbar
                  logoSize={this.state.logoSize}
                  showLogo={this.state.showLogo}
                />
              </Sticky>
            </Responsive>
            <Sidebar.Pushable
              className="no-shadow-border"
              as={Segment}
              style={{
                margin: "0px",
                border: "none",
                background: "transparent"
              }}
            >
              <Sidebar
                style={{ margin: "0px", border: "none", width: "50%" }}
                as={Menu}
                stackable
                animation="overlay"
                icon="labeled"
                vertical
                visible={sidebarOpen}
              >
                <Menu.Item
                  as={Link}
                  to="/"
                  onClick={this.handleSidebarToggle(false)}
                >
                  Home
                </Menu.Item>

                <Menu.Item
                  as={Link}
                  to="/process/core"
                  onClick={this.handleSidebarToggle(false)}
                >
                  Process
                </Menu.Item>

                <Menu.Item
                  as={Link}
                  to="/about"
                  onClick={this.handleSidebarToggle(false)}
                >
                  Know about Us
                </Menu.Item>

                <Menu.Item
                  as={Link}
                  to="/contact"
                  onClick={this.handleSidebarToggle(false)}
                >
                  Contact Us
                </Menu.Item>
              </Sidebar>
              <Sidebar.Pusher style={{ border: "none", boxShadow: "none" }}>
                <Switch>
                  <Route exact path="/" component={LandingPage} />
                  <Route path="/contact" component={ContactUs} />
                  <Route path="/process/:id" component={MainProcess} />
                  <Route path="/about" component={AboutUsPage} />
                  <Route
                    path="/pollutioncontrol"
                    component={PollutionControl}
                  />
                </Switch>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
            <Footer />
          </div>
        )}
        {this.checkIfSafari() && this.showSupportAlert()}
      </div>
    );
  }
}

export default App;
