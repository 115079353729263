import React, { Component } from "react";
import { Menu, Image, Dropdown, Segment, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const HOME = "home";
const ABOUT = "about";
const CONTACT = "contact";
const POLLUTION_CONTROL = "pollution_control";

function MenuItems(activeItem, changeActiveItem) {
  return (
    <div style={{ textAlign: "center" }}>
      <Menu
        secondary
        style={{
          backgroundColor: "white",
          marginTop: "0px",
          marginBottom: "0px",
          textAlign: "center",
          border: "none"
        }}
        compact
      >
        <Menu.Item
          as={Link}
          to="/"
          name="Home"
          active={activeItem === HOME}
          onClick={changeActiveItem(HOME)}
        />

        <Dropdown item text="Our Process">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/process/core">
              Process and Facilities
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/">
              Pollution Control
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/">
              Utilities
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/">
              Quality Assurance
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          as={Link}
          to="/pollutioncontrol"
          name="Pollution Control"
          active={activeItem === POLLUTION_CONTROL}
          onClick={changeActiveItem(POLLUTION_CONTROL)}
        />
        <Menu.Item
          as={Link}
          to="/about"
          name="Know about Us"
          active={activeItem === ABOUT}
          onClick={changeActiveItem(ABOUT)}
        />
        <Menu.Item
          as={Link}
          to="/contact"
          name="Contact Us"
          active={activeItem === CONTACT}
          onClick={changeActiveItem(CONTACT)}
        />
      </Menu>
    </div>
  );
}

class Navbar extends Component {
  state = {
    activeItem: HOME
  };

  changeActiveItem = item => () => {
    this.setState({
      activeItem: item
    });
  };

  decideBackground = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return { backgroundColor: "orange" };
      case "medium":
        return { background: "transparent" };
      default:
        return { backgroundColor: "none" };
    }
  };

  decodeLogoPosition = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return "center";
      case "medium":
        return true;
      default:
        return true;
    }
  };

  decideLogo = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return false;
      case "small":
        return true;
      default:
        return true;
    }
  };

  render() {
    const { activeItem } = this.state;

    return (
      <div
        className={this.props.showLogo ? "" : "top-navigation-bar"}
        style={{
          backgroundColor: "white",
          position: "relative"
        }}
      >
        {this.props.showLogo && (
          <Menu
            secondary
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              paddingRight: "2%",
              width: "100%",
              border: "none"
            }}
          >
            {/* <Menu.Item as={Link} to="/">
            <Image size={this.props.logoSize} src="/assets/logic.jpg" />
          </Menu.Item> */}

            <Menu.Item as={Link} to="/" style={{ padding: "0px 0px" }}>
              <Image size="large" src="/assets/logo_nav_mi.png" />
              {/* <Header size={this.props.logoSize} inverted>
                <Image src="/assets/logo_fbh.jpg" />
                <Header.Content>
                  Fancy Boot House{" "}
                  <p className="small-text"> We care for What you Wear!</p>
                </Header.Content>
              </Header> */}
            </Menu.Item>
            <Menu.Menu
              position="right"
              secondary="true"
              pointing="true"
              style={{ padding: "1% 1%" }}
            >
              <Menu.Item
                as={Link}
                to="/"
                name="Home"
                active={activeItem === HOME}
                onClick={this.changeActiveItem(HOME)}
              />

              <Dropdown item text="Our Process">
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/process/core">
                    Process and Facilities
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">
                    Pollution Control
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">
                    Utilities
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">
                    Quality Assurance
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Item
                as={Link}
                to="/pollutioncontrol"
                name="Pollution Control"
                active={activeItem === POLLUTION_CONTROL}
                onClick={this.changeActiveItem(POLLUTION_CONTROL)}
              />

              <Menu.Item
                as={Link}
                to="/about"
                name="Know about Us"
                active={activeItem === ABOUT}
                onClick={this.changeActiveItem(ABOUT)}
              />

              <Menu.Item
                as={Link}
                to="/contact"
                name="Contact Us"
                active={activeItem === CONTACT}
                onClick={this.changeActiveItem(CONTACT)}
              />

              <Menu.Item
                as={"a"}
                href="mailto:metalacindustries@gmail.com"
                onClick={this.changeActiveItem(CONTACT)}
              >
                <Button secondary>Request a Quote</Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}
        {!this.props.showLogo && (
          <Segment basic>
            {" "}
            {MenuItems(activeItem, this.changeActiveItem)}
          </Segment>
        )}
        {/* </Segment> */}
      </div>
    );
  }
}

export default Navbar;
