import React, { Component } from "react";
import {
  Grid,
  Image,
  Divider,
  Header,
  Container,
  Segment,
  Responsive
} from "semantic-ui-react";
import CategoryBar from "./CategoryBar";
import { processInformation } from "../Utils/processInformation";
import { OurSpeciality } from "../LandingPage/OurSpeciality";

class MainProcess extends Component {
  state = {
    selectedImage: {
      image: "assets/process/core/cbcs.jpg",
      imageName: "Cold Box Core Shooter"
    },
    selectedProcess: processInformation["core"]
  };

  componentDidMount() {
    let values = ["core", "moulding", "melting", "testing", "finishing"];
    if (values.includes(this.props.match.params.id)) {
      let brandInfo = processInformation[this.props.match.params.id];
      this.setState({
        selectedProcess: brandInfo,
        selectedImage: brandInfo["imageDetails"][0]
      });
      this.changeDescriptionText(brandInfo["imageDetails"][0]);
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      let brandInfo = processInformation[this.props.match.params.id];
      this.setState({
        selectedProcess: brandInfo,
        selectedImage: brandInfo["imageDetails"][0]
      });
      this.changeDescriptionText(brandInfo["imageDetails"][0]);
    }
  }

  handleChangeImage = index => () => {
    this.setState({
      selectedImage: index
    });
    this.changeDescriptionText(index);
  };

  changeDescriptionText = index => {
    var element = document.getElementById("imageDescription");
    if (index["description"] != null) {
      document.getElementById("description_holder").style.display = "block";
      element.innerHTML = index["description"];
    } else {
      document.getElementById("description_holder").style.display = "none";
    }
  };

  render() {
    const { selectedProcess, selectedImage } = this.state;
    return (
      <div>
        <Responsive maxWidth="772">
          <Image fluid src="/assets/process_banner.jpg" />
        </Responsive>
        <CategoryBar active={this.props.match.params.id} />
        <div style={{ padding: "2% 2%" }}>
          <Header textAlign="center" as="h1">
            <Header.Content>{selectedProcess.name}</Header.Content>

            <Header.Subheader>
              <Container textAlign="justified">
                <p style={{ marginTop: "1%" }}>{selectedProcess.description}</p>
              </Container>
            </Header.Subheader>
          </Header>
        </div>

        <Divider horizontal>
          <Header as="h2">
            <Header.Content>
              <p className="large-text" style={{ fontWeight: "bold" }}>
                Our Facilities for {selectedProcess.name}
              </p>
            </Header.Content>
            <Header.Subheader>
              <p className="small-text">Tested for High Quality Production</p>
            </Header.Subheader>
          </Header>
        </Divider>
        <Grid padded centered stackable>
          <Grid.Row centered style={{ paddingBottom: "0px" }}>
            <Grid.Column width="7">
              <div style={{ position: "relative" }}>
                <Image
                  centered
                  style={{ border: "1px solid #DFDFDF", zIndex: "1" }}
                  src={selectedImage.image}
                />
                <Segment
                  id="description_holder"
                  textAlign="left"
                  style={{ width: "100%" }}
                  color="orange"
                  basic
                  tertiary
                >
                  <div id="imageDescription"></div>
                </Segment>
                <Header
                  textAlign="center"
                  style={{
                    width: "100%"
                  }}
                  block
                >
                  {selectedImage["imageName"]}
                </Header>
              </div>
              <Grid padded centered>
                <Grid.Row
                  centered
                  columns={selectedProcess["imageDetails"].length}
                  style={{ padding: "2%" }}
                >
                  {selectedProcess["imageDetails"].map(data => {
                    return (
                      <Grid.Column
                        textAlign="center"
                        className="pointer_on_hover"
                        onClick={this.handleChangeImage(data)}
                      >
                        <Image
                          bordered
                          dimmer
                          size="medium"
                          centered
                          src={data["image"]}
                        />
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment basic secondary>
          <OurSpeciality showHeading={true} />
        </Segment>
      </div>
    );
  }
}

export default MainProcess;
