import React, { Component } from "react";
import { Menu, Icon, Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Link } from "react-router-dom";

class MobileNavbar extends Component {
  render() {
    const { handleSidebarToggle } = this.props;
    const { value } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Menu
          pointing
          secondary
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            border: "none"
          }}
        >
          <Menu.Item onClick={handleSidebarToggle(!value)}>
            <Icon name="bars" size="big" />
            <Image
              as={Link}
              to="/"
              size="medium"
              src="/assets/logo_nav_mi.png"
            />
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default MobileNavbar;
